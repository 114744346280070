import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    countries: [],
    country: null,
    meta: { currentPage: 0, nextPage: false, prevPage: false, totalPages: 0, totalRecords: 0 },
};
// ----------------------------------------------------------------------
export const getCountries = createAsyncThunk('country/getCountries', async ({ isPaging = false }) => {
    let data;
    const queryParams = {
        isPaging,
    };
    const url = createDynamicURL('/countries', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
// ----------------------------------------------------------------------
const slice = createSlice({
    name: 'state',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCountries.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getCountries.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.countries = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getCountries.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice.reducer;
